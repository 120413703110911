.image-clean {
  height: 512px;
  position: relative;
  width: 512px;
}

.image-clean .trash {
  height: 481px;
  left: 39px;
  position: absolute;
  top: 15px;
  width: 433px;
}
