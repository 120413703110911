.image-games {
  height: 512px;
  overflow: hidden;
  width: 512px;
}

.image-games .group-16 {
  height: 434px;
  left: -6px;
  position: relative;
  top: 41px;
  width: 518px;
}

.image-games .overlap {
  height: 434px;
  position: relative;
}

.image-games .overlap-group3 {
  height: 366px;
  left: 0;
  position: absolute;
  top: 0;
  width: 444px;
}

.image-games .group-15 {
  height: 203px;
  left: 0;
  position: absolute;
  top: 163px;
  width: 262px;
}

.image-games .overlap-group-2 {
  height: 203px;
  left: 6px;
  position: relative;
  width: 257px;
}

.image-games .ellipse {
  background-color: #6c460e;
  border-radius: 87.35px;
  height: 175px;
  left: 43px;
  position: absolute;
  top: 14px;
  width: 175px;
}

.image-games .tarl {
  height: 203px;
  left: 0;
  position: absolute;
  top: 0;
  width: 257px;
}

.image-games .group-9 {
  height: 216px;
  left: 206px;
  position: absolute;
  top: 0;
  width: 239px;
}

.image-games .overlap-group1 {
  height: 216px;
  position: relative;
}

.image-games .ellipse-4 {
  background-color: #58de56;
  border-radius: 107.66px;
  height: 215px;
  left: 11px;
  position: absolute;
  top: 0;
  width: 215px;
}

.image-games .mask-group-5 {
  height: 204px;
  left: 0;
  position: absolute;
  top: 11px;
  width: 239px;
}

.image-games .group-7 {
  height: 199px;
  left: 294px;
  position: absolute;
  top: 235px;
  width: 223px;
}

.image-games .overlap-group2 {
  height: 199px;
  position: relative;
}

.image-games .ellipse-5 {
  background-color: #fdc90f;
  border-radius: 99.68px;
  height: 199px;
  left: 24px;
  position: absolute;
  top: 0;
  width: 199px;
}

.image-games .bask {
  height: 186px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 190px;
}
