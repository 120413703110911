.image-egg {
  height: 512px;
  position: relative;
  width: 512px;
}

.image-egg .image {
  height: 352px;
  left: 6px;
  position: absolute;
  top: 80px;
  width: 500px;
}
