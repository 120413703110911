.image-piano {
  height: 512px;
  position: relative;
  width: 512px;
}

.image-piano .piano {
  height: 398px;
  left: 6px;
  position: absolute;
  top: 114px;
  width: 499px;
}
