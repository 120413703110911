p,
h1,
h2,
div {
  font-family: "Baloo", Helvetica;
}

h1 {
  font-size: 48px
}

.DASHBOARD {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.DASHBOARD .iphone-mini {
  background-color: #ffffff;
  border: 1px none;
}

.DASHBOARD .rectangle-2 {
  background: linear-gradient(180deg, rgb(0, 161.84, 252.88) 0%, rgb(102, 199.92, 255) 100%);
  position: absolute;
}

.DASHBOARD .group-822 {
  height: 1009px;
  left: 72px;
  position: absolute;
  top: 6071px;
  width: 375px;
}

.DASHBOARD .group-815 {
  height: 133px;
  left: 72px;
  position: absolute;
  top: 6947px;
  width: 375px;
}

.DASHBOARD .navegao {
  background-color: #b5b5b6;
  height: 133px;
  position: relative;
}

.DASHBOARD .text-wrapper {
  color: #3478f6;
  font-family: "SF Pro Display-Regular", Helvetica;
  font-size: 22px;
  font-weight: 400;
  left: 175px;
  letter-spacing: 0.77px;
  line-height: normal;
  position: absolute;
  top: 63px;
  white-space: nowrap;
}

.DASHBOARD .text-wrapper-2 {
  color: #3478f6;
  font-family: "SF Pro Display-Regular", Helvetica;
  font-size: 22px;
  font-weight: 400;
  left: 252px;
  letter-spacing: 0.77px;
  line-height: normal;
  position: absolute;
  top: 63px;
  white-space: nowrap;
}

.DASHBOARD .text-wrapper-3 {
  color: #3478f6;
  font-family: "SF Pro Display-Regular", Helvetica;
  font-size: 22px;
  font-weight: 400;
  left: 332px;
  letter-spacing: 0.77px;
  line-height: normal;
  position: absolute;
  top: 62px;
  white-space: nowrap;
}

.DASHBOARD .vector {
  height: 19px;
  left: 20px;
  position: absolute;
  top: 67px;
  width: 9px;
}

.DASHBOARD .vector-2 {
  height: 19px;
  left: 102px;
  position: absolute;
  top: 67px;
  width: 9px;
}

.DASHBOARD .line {
  height: 5px;
  left: 120px;
  position: absolute;
  top: 120px;
  width: 135px;
}

.DASHBOARD .url {
  height: 44px;
  left: 21px;
  position: absolute;
  top: 8px;
  width: 341px;
}

.DASHBOARD .overlap-2 {
  background-color: #ffffff;
  border-radius: 12px;
  height: 44px;
  position: relative;
  width: 335px;
}

.DASHBOARD .overlap-3 {
  height: 20px;
  left: 86px;
  position: absolute;
  top: 12px;
  width: 191px;
}

.DASHBOARD .text-wrapper-4 {
  color: #000000;
  font-family: "SF Pro-Regular", Helvetica;
  font-size: 17px;
  font-weight: 400;
  left: 0;
  letter-spacing: -0.34px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  white-space: nowrap;
}

.DASHBOARD .text-wrapper-5 {
  color: #000000;
  font-family: "SF Pro Display-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 35px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 2px;
  width: 16px;
}

.DASHBOARD .group-17 {
  height: 20px;
  left: 14px;
  position: absolute;
  top: 12px;
  width: 25px;
}

.DASHBOARD .overlap-group-4 {
  height: 20px;
  position: relative;
  width: 21px;
}

.DASHBOARD .text-wrapper-6 {
  color: #000000;
  font-family: "SF Pro-Regular", Helvetica;
  font-size: 17px;
  font-weight: 400;
  left: 8px;
  letter-spacing: -0.34px;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 13px;
}

.DASHBOARD .text-wrapper-7 {
  color: #000000;
  font-family: "SF Pro-Regular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  left: 0;
  letter-spacing: -0.24px;
  line-height: normal;
  position: absolute;
  top: 5px;
  white-space: nowrap;
  width: 10px;
}

.DASHBOARD .text-wrapper-8 {
  color: #000000;
  font-family: "SF Pro Display-Regular", Helvetica;
  font-size: 15px;
  font-weight: 400;
  left: 306px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 14px;
  white-space: nowrap;
  width: 19px;
}

.DASHBOARD .rectangle-1 {
  object-fit: cover;
  position: absolute;
}

.DASHBOARD .clouds {
  height: 94px;
  left: 0;
  position: absolute;
  top: 164px;
  width: 521px;
}

.DASHBOARD .overlap-4 {
  height: 94px;
  left: 72px;
  position: relative;
  width: 375px;
}

.DASHBOARD .asset-9 {
  height: 67px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 11px;
  width: 54px;
}

.DASHBOARD .asset-8 {
  height: 75px;
  left: 316px;
  object-fit: cover;
  position: absolute;
  top: 9px;
  width: 59px;
}

.DASHBOARD .asset-1 {
  height: 52px;
  left: 157px;
  object-fit: cover;
  position: absolute;
  top: 24px;
  width: 101px;
}

.DASHBOARD .asset-2 {
  height: 58px;
  left: 103px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 101px;
}

.DASHBOARD .asset-3 {
  height: 75px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 3px;
  width: 132px;
}

.DASHBOARD .asset-4 {
  height: 76px;
  left: 194px;
  object-fit: cover;
  position: absolute;
  top: 6px;
  width: 136px;
}

.DASHBOARD .asset-6 {
  height: 64px;
  left: 114px;
  object-fit: cover;
  position: absolute;
  top: 26px;
  width: 107px;
}

.DASHBOARD .asset-7 {
  height: 12px;
  left: 103px;
  position: absolute;
  top: 82px;
  width: 12px;
}

.DASHBOARD .asset-5 {
  height: 67px;
  left: 253px;
  object-fit: cover;
  position: absolute;
  top: 16px;
  width: 122px;
}

.DASHBOARD .icon-cloud {
  height: 36px;
  left: 72px;
  object-fit: cover;
  position: absolute;
  top: 338px;
  width: 50px;
}

.DASHBOARD .asset-10 {
  height: 65px;
  left: 382px;
  position: absolute;
  top: 273px;
  width: 65px;
}

.DASHBOARD .OZMO-adventureland-headline {
  height: 78px;
  left: 87px;
  position: absolute;
  top: 288px;
  width: 346px;
}

.DASHBOARD .p {
  color: #ffffff;
  font-family: "Baloo", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 89px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 364px;
  width: 334px;
}

.DASHBOARD .group-816 {
  height: 493px;
  position: relative;
  top: 400px;
  margin: 0 auto;
  width: 339px;
}

.DASHBOARD .overlap-group4 {
  height: 493px;
  position: relative;
  width: 335px;
}

.DASHBOARD .rectangle-3 {
  background-color: #ffffff;
  border-radius: 40px;
  height: 443px;
  left: 0;
  position: absolute;
  top: 0;
  width: 335px;
}

.DASHBOARD .text-wrapper-9 {
  color: #00a1ff;
  font-family: "Baloo", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 75px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 16px;
}

.DASHBOARD .en-heyecan-verici-k-s-m-kendi-karakterinizi-yaratabilir-sa-stilini-bile-de-i-tirebilirsiniz {
  color: #005788;
  font-family: "Baloo", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 24px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 60px;
  width: 287px;
}

.DASHBOARD .group-818 {
  height: 1063px;
  position: relative;
  top: 550px;
  margin: 0 auto;
  width: 339px;
}

.DASHBOARD .overlap-group5 {
  height: 493px;
  left: 13px;
  position: absolute;
  top: 0;
  width: 335px;
}

.DASHBOARD .rectangle-4 {
  background-color: #ffffff;
  border-radius: 40px;
  height: 443px;
  left: 0;
  position: absolute;
  top: 0;
  width: 335px;
}

.DASHBOARD .text-wrapper-10 {
  color: #00a1ff;
  font-family: "Baloo", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 78px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 16px;
}

.DASHBOARD .yeni-e-yalar-alabilirsin-t-m-bunlar-ozmo-nun-e-lenceli-d-kk-n-nda-sizi-bekliyor {
  color: #005788;
  font-family: "Baloo", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 24px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 53px;
  width: 287px;
}

.DASHBOARD .group-819 {
  height: 542px;
  left: 0;
  position: absolute;
  top: 521px;
  width: 365px;
}

.DASHBOARD .ozmo-macera-d-nyas-nda-sizi-b-y-k-bir-ehir-kar-l-yor {
  color: #ffffff;
  font-family: "Baloo", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 62px;
  width: 361px;
}

.DASHBOARD .h-1 {
  color: #ffffff;
  font-family: "Baloo", Helvetica;
  font-size: 32px;
  font-weight: 400;
  left: 16px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
}

.DASHBOARD .group-19 {
  background-color: #d9d9d9;
  height: 157px;
  left: 192px;
  position: absolute;
  top: 385px;
  width: 157px;
}

.DASHBOARD .group-820 {
  height: 202px;
  left: 14px;
  position: absolute;
  top: 172px;
  width: 336px;
}

.DASHBOARD .overlap-group-5 {
  height: 202px;
  position: relative;
}

.DASHBOARD .image-008-0003 {
  height: 189px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 336px;
}

.DASHBOARD .group-817 {
  height: 530px;
  position: relative;
  margin: 0 auto;
  top: 480px;
  width: 339px;
}

.DASHBOARD .overlap-group6 {
  height: 530px;
  position: relative;
  width: 335px;
}

.DASHBOARD .rectangle-5 {
  background-color: #ffffff;
  border-radius: 40px;
  height: 494px;
  left: 0;
  position: absolute;
  top: 0;
  width: 335px;
}

.DASHBOARD .text-wrapper-11 {
  color: #00a1ff;
  font-family: "Baloo", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 49px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 16px;
  width: 238px;
}

.DASHBOARD .text-wrapper-12 {
  color: #005788;
  font-family: "Baloo", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 24px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 98px;
  width: 287px;
}

.DASHBOARD .text-wrapper-13 {
  color: #ffffff;
  font-family: "Baloo", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 87px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 2692px;
  width: 340px;
}

.DASHBOARD .group-20 {
  height: 350px;
  left: 93px;
  position: absolute;
  top: 2865px;
  width: 338px;
}

.DASHBOARD .overlap-group8 {
  background-color: #ffffff;
  border-radius: 33.68px;
  height: 350px;
  position: relative;
  width: 336px;
}

.DASHBOARD .text-wrapper-14 {
  color: #00a1ff;
  font-family: "Baloo", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 86px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 13px;
}

.DASHBOARD .group-21 {
  height: 350px;
  left: 93px;
  position: absolute;
  top: 3235px;
  width: 338px;
}

.DASHBOARD .overlap-group9 {
  background-color: #ffffff;
  border-radius: 33.68px;
  height: 350px;
  position: relative;
  width: 336px;
}

.DASHBOARD .text-wrapper-15 {
  color: #00a1ff;
  font-family: "Baloo", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 79px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 13px;
}

.DASHBOARD .group-22 {
  height: 350px;
  left: 93px;
  position: absolute;
  top: 3606px;
  width: 338px;
}





.DASHBOARD .overlap-group10 {
  background-color: #ffffff;
  border-radius: 33.68px;
  height: 350px;
  position: relative;
  width: 336px;
}

.DASHBOARD .text-wrapper-16 {
  color: #00a1ff;
  font-family: "Baloo", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 68px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 13px;
}

.DASHBOARD .group-25 {
  height: 350px;
  left: 93px;
  position: absolute;
  top: 3976px;
  width: 338px;
}

.DASHBOARD .overlap-group11 {
  background-color: #ffffff;
  border-radius: 33.68px;
  height: 350px;
  position: relative;
  width: 336px;
}

.DASHBOARD .text-wrapper-17 {
  color: #00a1ff;
  font-family: "Baloo", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 64px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 13px;
}

.DASHBOARD .group-24 {
  height: 350px;
  left: 93px;
  position: absolute;
  top: 4346px;
  width: 338px;
}

.DASHBOARD .overlap-group12 {
  background-color: #ffffff;
  border-radius: 33.68px;
  height: 350px;
  position: relative;
  width: 336px;
}

.DASHBOARD .text-wrapper-18 {
  color: #00a1ff;
  font-family: "Baloo", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 39px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 13px;
}

.DASHBOARD .group-23 {
  height: 350px;
  left: 93px;
  position: absolute;
  top: 4717px;
  width: 338px;
}

.DASHBOARD .overlap-group13 {
  background-color: #ffffff;
  border-radius: 33.68px;
  height: 350px;
  position: relative;
  width: 336px;
}

.DASHBOARD .text-wrapper-19 {
  color: #00a1ff;
  font-family: "Baloo", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 86px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 13px;
}

.DASHBOARD .group-26 {
  height: 350px;
  left: 93px;
  position: absolute;
  top: 5087px;
  width: 338px;
}

.DASHBOARD .overlap-group14 {
  background-color: #ffffff;
  border-radius: 33.68px;
  height: 350px;
  position: relative;
  width: 336px;
}

.DASHBOARD .text-wrapper-20 {
  color: #00a1ff;
  font-family: "Baloo", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 81px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 13px;
}

.DASHBOARD .group-27 {
  height: 416px;
  left: 90px;
  position: absolute;
  top: 5457px;
  width: 338px;
}

.DASHBOARD .overlap-group15 {
  background-color: #ffffff;
  border-radius: 40px;
  height: 416px;
  position: relative;
  width: 336px;
}

.DASHBOARD .hatta-ozmo-nun-gizemli-yumurtas-n-bile-a-abilirsiniz {
  color: #00a1ff;
  font-family: "Baloo", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 37px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 18px;
  width: 273px;
}

.DASHBOARD .text-wrapper-21 {
  color: #ffffff;
  font-family: "Baloo", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 92px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 5914px;
  width: 335px;
}

.DASHBOARD .text-wrapper-22 {
  color: #ffffff;
  font-family: "Baloo", Helvetica;
  font-size: 24px;
  font-weight: 400;
  left: 92px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 6237px;
  width: 335px;
}

.DASHBOARD .text-wrapper-23 {
  color: #ffffff;
  font-family: "Baloo", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 100px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 6173px;
}

.DASHBOARD .text-wrapper-24 {
  color: #ffffff;
  font-family: "Baloo", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 80px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 6353px;
  width: 347px;
}

.DASHBOARD .text-wrapper-25 {
  color: #ffffff;
  font-family: "Baloo", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 160px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 6522px;
}

.DASHBOARD .imdi-ozmo-macera-d-nyas-na-gelin-yeni-oyunlar-ke-fedin-yeni-arkada-lar-edinin-ve-en-nemlisi-e-lenin-s-n-rs-z-macera-ve-e-lenceye-ho-geldiniz {
  color: #ffffff;
  font-family: "Baloo", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 92px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 6586px;
  width: 334px;
}

.DASHBOARD .image-009-0015 {
  height: 157px;
  left: 92px;
  position: absolute;
  top: 6699px;
  width: 336px;
}

.DASHBOARD .HEADER {
  background-color: transparent;
  height: 89px;
  position: absolute;
  top: 0;
  z-index: 9999;
  width: 1247px;
  left: calc(50vw - (1247px / 2));
}

.DASHBOARD .HEADER_INNER {
  position: relative;
}

.DASHBOARD .ANASAYFA {
  height: 89px;
  left: 145px;
  position: absolute;
  top: 0;
  width: 191px;
}

.DASHBOARD .ozmo-adventure-land-logo-wrapper {
  background-image: url(../../public/img/rectangle-15.svg);
  background-size: 100% 100%;
  height: 88px;
  left: -4px;
  position: relative;
  top: 9px;
  width: 199px;
}

.DASHBOARD .HAKKINDA {
  height: 89px;
  left: 500px;
  position: absolute;
  top: 0;
  width: 193px;
}

.DASHBOARD .div-wrapper {
  background-image: url(../../public/img/rectangle-15.svg);
  background-size: 100% 100%;
  height: 88px;
  left: -4px;
  position: relative;
  top: 9px;
  width: 199px;
}

.DASHBOARD .text-wrapper-26 {
  color: #3aa4ff;
  font-family: "Baloo", Helvetica;
  font-size: 26px;
  font-weight: 400;
  left: 41px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 13px;
}

.DASHBOARD .LETM {
  height: 89px;
  left: 720px;
  position: absolute;
  top: 0;
  width: 193px;
}

.DASHBOARD .overlap-5 {
  background-image: url(../../public/img/rectangle-12.svg);
  background-size: 100% 100%;
  height: 88px;
  left: -4px;
  position: relative;
  top: 9px;
  width: 199px;
}

.DASHBOARD .text-wrapper-27 {
  color: #3aa4ff;
  font-family: "Baloo", Helvetica;
  font-size: 26px;
  font-weight: 400;
  left: 52px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 13px;
}

.DASHBOARD .INDIR {
  height: 89px;
  left: 940px;
  position: absolute;
  top: 0;
  width: 193px;
}

.DASHBOARD .overlap-6 {
  background-image: url(../../public/img/rectangle-13.svg);
  background-size: 100% 100%;
  height: 88px;
  left: -4px;
  position: relative;
  top: 9px;
  width: 199px;
}

.DASHBOARD .text-wrapper-28 {
  color: #3aa4ff;
  font-family: "Baloo", Helvetica;
  font-size: 26px;
  font-weight: 400;
  left: 28px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 13px;
}

.DASHBOARD .app-store-icon {
  align-items: flex-start;
  background-color: #00a4ff;
  border-radius: 5px;
  display: flex;
  gap: 2px;
  left: 109px;
  padding: 2px;
  position: absolute;
  top: 20px;
  width: fit-content;
}

.DASHBOARD .google-play-icon {
  align-items: flex-start;
  background-color: #00a4ff;
  border-radius: 5px;
  display: flex;
  gap: 2px;
  left: 147px;
  padding: 2px;
  position: absolute;
  top: 20px;
  width: fit-content;
}

.DASHBOARD .overlap-wrapper {
  height: 412px;
  left: 0;
  position: absolute;
  width: 100%;
}

.DASHBOARD .overlap-7 {
  height: 386px;
  position: relative;
  width: 100%;
  top: 840px;
  background: url(../../public/img/clouds.svg);
  background-size: cover;
}

.DASHBOARD .asset-11 {
  height: 275px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 46px;
  width: 406px;
}

.DASHBOARD .asset-12 {
  height: 309px;
  left: 1488px;
  object-fit: cover;
  position: absolute;
  top: 38px;
  width: 432px;
}

.DASHBOARD .asset-13 {
  height: 213px;
  left: 831px;
  object-fit: cover;
  position: absolute;
  top: 98px;
  width: 417px;
}

.DASHBOARD .asset-14 {
  height: 239px;
  left: 609px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 416px;
}

.DASHBOARD .asset-15 {
  height: 309px;
  left: 186px;
  object-fit: cover;
  position: absolute;
  top: 12px;
  width: 544px;
}

.DASHBOARD .asset-16 {
  height: 314px;
  left: 984px;
  object-fit: cover;
  position: absolute;
  top: 24px;
  width: 561px;
}

.DASHBOARD .asset-17 {
  height: 262px;
  left: 654px;
  object-fit: cover;
  position: absolute;
  top: 107px;
  width: 441px;
}

.DASHBOARD .asset-18 {
  height: 48px;
  left: 609px;
  position: absolute;
  top: 338px;
  width: 49px;
}

.DASHBOARD .asset-19 {
  height: 275px;
  left: 1229px;
  object-fit: cover;
  position: absolute;
  top: 67px;
  width: 519px;
}

.DASHBOARD .asset-20 {
  height: 103px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 1460px;
  width: 173px;
}

.DASHBOARD .asset-21 {
  height: 183px;
  right: 0;
  object-fit: initial;
  position: absolute;
  top: 1303px;
  width: 308px;
}

.DASHBOARD .PART-WELCOME {
  height: 940px;
  position: absolute;
  top: 1314px;
  width: 1243px;
  width: 1247px;
  left: calc(50vw - (1247px / 2));
}

.DASHBOARD .PART-WELCOME_INNER {
  position: relative;
}

.DASHBOARD .CARD {
  height: 636px;
  left: 421px;
  position: absolute;
  top: 304px;
  width: 404px;
}

.DASHBOARD .overlap-8 {
  height: 636px;
  position: relative;
  width: 400px;
}

.DASHBOARD .rectangle-6 {
  background-color: #ffffff;
  border-radius: 40px;
  height: 580px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 399px;
}

.DASHBOARD .text-wrapper-29 {
  color: #005788;
  font-family: "Baloo", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 30px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 124px;
  width: 341px;
}

.DASHBOARD .text-wrapper-30 {
  color: #00a1ff;
  font-family: "Baloo", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 65px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 24px;
  width: 270px;
}

.DASHBOARD .overlap-9 {
  height: 245px;
  left: 89px;
  position: absolute;
  top: -5px;
  width: 1065px;
}

.DASHBOARD .OZMO-adventureland-headline-2 {
  height: 241px;
  left: 3%;
  position: absolute;
  top: 0;
  width: 1065px;
}

.DASHBOARD .text-wrapper-31 {
  color: #ffffff;
  font-family: "Baloo", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 257px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 151px;
  width: 544px;
}

.DASHBOARD .CARD-1 {
  height: 553px;
  left: 0;
  position: absolute;
  top: 387px;
  width: 405px;
}

.DASHBOARD .overlap-10 {
  height: 553px;
  position: relative;
  width: 401px;
}

.DASHBOARD .rectangle-7 {
  background-color: #ffffff;
  border-radius: 40px;
  height: 489px;
  left: 0;
  position: absolute;
  top: 0;
  width: 400px;
}

.DASHBOARD .text-wrapper-32 {
  color: #00a1ff;
  font-family: "Baloo", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 83px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 30px;
}

.DASHBOARD .en-heyecan-verici-k-s-m-kendi-karakterinizi-yaratabilir-sa-stilini-bile-de-i-tirebilirsiniz-2 {
  color: #005788;
  font-family: "Baloo", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 26px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 83px;
  width: 347px;
}

.DASHBOARD .CARD-3 {
  height: 553px;
  left: 841px;
  position: absolute;
  top: 387px;
  width: 404px;
}

.DASHBOARD .overlap-group7 {
  height: 553px;
  position: relative;
  width: 400px;
}

.DASHBOARD .rectangle-8 {
  background-color: #ffffff;
  border-radius: 40px;
  height: 489px;
  left: 0;
  position: absolute;
  top: 0;
  width: 400px;
}

.DASHBOARD .text-wrapper-33 {
  color: #00a1ff;
  font-family: "Baloo", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 87px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 24px;
}

.DASHBOARD .yeni-e-yalar-alabilirsin-t-m-bunlar-ozmo-nun-e-lenceli-d-kk-n-nda-sizi-bekliyor-2 {
  color: #005788;
  font-family: "Baloo", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 25px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 83px;
  width: 350px;
}

.DASHBOARD .PART-CITY {
  height: 1151px;
  left: 50%;
  position: absolute;
  top: 2316px;
  width: 1247px;
  left: calc(50vw - (1247px / 2));
}

.DASHBOARD .PART-CITY_INNER {
  position: relative;
}

.DASHBOARD .ozmo-macera-d-nyas-nda-sizi-b-y-k-bir-ehir-kar-l-yor-2 {
  color: #ffffff;
  font-family: "Baloo", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 154px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 84px;
  width: 949px;
}

.DASHBOARD .text-wrapper-34 {
  color: #ffffff;
  font-family: "Baloo", Helvetica;
  font-size: 46px;
  font-weight: 400;
  left: 394px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -5px;
}

.DASHBOARD .group-18 {
  height: 751px;
  left: 0;
  position: absolute;
  top: 183px;
  width: 1245px;
}

.DASHBOARD .overlap-group-6 {
  height: 751px;
  position: relative;
}

.DASHBOARD .image-2 {
  height: 700px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1245px;
}

.DASHBOARD .group-2 {
  height: 191px;
  width: 600px;
  left: calc(50vw - (1000px / 2));
  position: absolute;
  top: 1045px;
}

.DASHBOARD .qr-kod {
  background-color: #d9d9d9;
  height: 191px;
  left: 316px;
  position: absolute;
  top: 0;
  width: 191px;
}

.DASHBOARD .PART-CARDS {
  height: 1550px;
  left: 50%;
  position: absolute;
  top: 3586px;
  width: 1247px;
}

.DASHBOARD .PART-CARDS_INNER {
  left: -43%;
  position: relative;
}

.DASHBOARD .overlap-group-wrapper {
  height: 416px;
  left: 0;
  position: absolute;
  top: 146px;
  width: 401px;
}

.DASHBOARD .overlap-group-7 {
  background-color: #ffffff;
  border-radius: 40px;
  height: 416px;
  position: relative;
  width: 399px;
}

.DASHBOARD .text-wrapper-35 {
  color: #00a1ff;
  font-family: "Baloo", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 96px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 16px;
}

.DASHBOARD .overlap-group1-wrapper {
  height: 416px;
  left: 420px;
  position: absolute;
  top: 147px;
  width: 401px;
}

.DASHBOARD .overlap-11 {
  background-color: #ffffff;
  border-radius: 40px;
  height: 416px;
  position: relative;
  width: 399px;
}

.DASHBOARD .text-wrapper-36 {
  color: #00a1ff;
  font-family: "Baloo", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 88px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 16px;
}

.DASHBOARD .overlap-group2-wrapper {
  height: 416px;
  left: 841px;
  position: absolute;
  top: 147px;
  width: 401px;
}

.DASHBOARD .overlap-12 {
  background-color: #ffffff;
  border-radius: 40px;
  height: 416px;
  position: relative;
  width: 399px;
}

.DASHBOARD .text-wrapper-37 {
  color: #00a1ff;
  font-family: "Baloo", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 75px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 16px;
}

.DASHBOARD .overlap-group3-wrapper {
  height: 416px;
  left: 0;
  position: absolute;
  top: 595px;
  width: 401px;
}

.DASHBOARD .overlap-13 {
  background-color: #ffffff;
  border-radius: 40px;
  height: 416px;
  position: relative;
  width: 399px;
}

.DASHBOARD .text-wrapper-38 {
  color: #00a1ff;
  font-family: "Baloo", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 70px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 16px;
}

.DASHBOARD .overlap-group4-wrapper {
  height: 416px;
  left: 420px;
  position: absolute;
  top: 596px;
  width: 401px;
}

.DASHBOARD .overlap-14 {
  background-color: #ffffff;
  border-radius: 40px;
  height: 416px;
  position: relative;
  width: 399px;
}

.DASHBOARD .text-wrapper-39 {
  color: #00a1ff;
  font-family: "Baloo", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 38px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 16px;
}

.DASHBOARD .overlap-group5-wrapper {
  height: 416px;
  left: 841px;
  position: absolute;
  top: 596px;
  width: 401px;
}

.DASHBOARD .overlap-15 {
  background-color: #ffffff;
  border-radius: 40px;
  height: 416px;
  position: relative;
  width: 399px;
}

.DASHBOARD .text-wrapper-40 {
  color: #00a1ff;
  font-family: "Baloo", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 97px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 16px;
}

.DASHBOARD .overlap-group6-wrapper {
  height: 416px;
  left: 0;
  position: absolute;
  top: 1044px;
  width: 401px;
}

.DASHBOARD .overlap-16 {
  background-color: #ffffff;
  border-radius: 40px;
  height: 416px;
  position: relative;
  width: 399px;
}

.DASHBOARD .text-wrapper-41 {
  color: #00a1ff;
  font-family: "Baloo", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 91px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 16px;
}

.DASHBOARD .overlap-group7-wrapper {
  height: 416px;
  left: 420px;
  position: absolute;
  top: 1045px;
  width: 825px;
}

.DASHBOARD .overlap-17 {
  background-color: #ffffff;
  border-radius: 40px;
  height: 416px;
  position: relative;
  width: 823px;
}

.DASHBOARD .hatta-ozmo-nun-gizemli-yumurtas-n-bile-a-abilirsiniz-2 {
  color: #00a1ff;
  font-family: "Baloo", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 37px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 18px;
  width: 754px;
}

.DASHBOARD .text-wrapper-42 {
  color: #ffffff;
  font-family: "Baloo", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 146px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 949px;
}

.DASHBOARD .text-wrapper-43 {
  color: #ffffff;
  font-family: "Baloo", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 51px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 1503px;
  width: 1151px;
}

.DASHBOARD .group-28 {
  height: 1301px;
  left: 50%;
  position: absolute;
  top: 5178px;
  width: 1948px;
}

.DASHBOARD .group-28_INNER {
  position: relative;
  left: -43%;
}

.DASHBOARD .overlap-18 {
  background-image: url(../../public/img/group-6.png);
  background-size: 100% 100%;
  height: 1301px;
  left: 9px;
  position: relative;
  width: 1920px;
}

.DASHBOARD .text-wrapper-44 {
  color: #ffffff;
  font-family: "Baloo", Helvetica;
  font-size: 30px;
  font-weight: 400;
  left: 487px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 202px;
  width: 949px;
}

.DASHBOARD .text-wrapper-45 {

  color: #ffffff;
  font-family: "Baloo", Helvetica;
  font-size: 46px;
  font-weight: 400;
  left: 719px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 113px;
}

.DASHBOARD .text-wrapper-46 {
  color: #ffffff;
  font-family: "Baloo", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 530px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 269px;
  width: 862px;
}

.DASHBOARD .text-wrapper-47 {
  color: #ffffff;
  font-family: "Baloo", Helvetica;
  font-size: 46px;
  font-weight: 400;
  left: 816px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 389px;
}

.DASHBOARD .imdi-ozmo-macera-d-nyas-na-gelin-yeni-oyunlar-ke-fedin-yeni-arkada-lar-edinin-ve-en-nemlisi-e-lenin-s-n-rs-z-macera-ve-e-lenceye-ho-geldiniz-2 {
  color: #ffffff;
  font-family: "Baloo", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 524px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 478px;
  width: 862px;
}

.DASHBOARD .image-3 {
  height: 585px;
  left: 333px;
  position: absolute;
  top: 580px;
  width: 1251px;
}

.DASHBOARD .header {
  height: 89px;
  width: 1246px;
  background-color: #0000;
  position: fixed;
  top: -9px;
  left: 15%;
  z-index: 100;
}

.DASHBOARD .overlap-19 {
  height: 80px;
  position: relative;
  width: 375px;
  margin: 0 auto;
}

.DASHBOARD .rectangle-9 {
  background-color: #ffffff;
  height: 80px;
  left: 0;
  position: absolute;
  top: 0;
  width: 375px;
}

.DASHBOARD .text-wrapper-48 {
  color: #ffffff;
  font-family: "Baloo", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 120px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 28px;
}

.DASHBOARD .text-wrapper-49 {
  color: #ffffff;
  font-family: "Baloo", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 201px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 28px;
}

.DASHBOARD .group-821 {
  height: 22px;
  left: 252px;
  position: absolute;
  top: 34px;
  width: 90px;
}

.DASHBOARD .text-wrapper-50 {
  color: #ffffff;
  font-family: "Baloo", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: -1px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -1px;
}

.DASHBOARD .simple-icons-appstore-wrapper {
  align-items: flex-start;
  background-color: #00a4ff;
  border-radius: 3.21px;
  display: flex;
  gap: 1.29px;
  left: 44px;
  padding: 1.29px;
  position: absolute;
  top: 1px;
  width: fit-content;
}

.DASHBOARD .ion-logo-google-playstore-wrapper {
  align-items: flex-start;
  background-color: #00a4ff;
  border-radius: 3.21px;
  display: flex;
  gap: 1.29px;
  left: 70px;
  padding: 1.29px;
  position: absolute;
  top: 1px;
  width: fit-content;
}

.DASHBOARD .vector-25 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 80px;
  width: 375px;
}


.ozmo-adventure-land-logo-2 {
  background-image: url(../../public/img/ozmo-al-1-2-2@2x.png);
  height: 200px;
  left: 33%;
  top: 77px;
  width: 400px;
}

.ozmo-adventure-land-logo-3 {
  background-position: 50% 50%;
  background-size: cover;
  position: absolute;
}

.haydi-sende-maceraya-katl {
  /* -webkit-text-stroke: 5px var(--azure-radiance-3); */
  color: white;
  font-weight: 400;
  line-height: normal;
  margin-right: 2px;
  margin-top: -5px;
  min-height: 72px;
  min-width: 591px;
  text-align: center;
}

.DASHBOARD .footer {
  top: 570px;
  width: 100%;
  background-color: #484f7f;
  color: #fff;
  height: 64px;
  position: relative;
  top: 3583px;
}

.footer-logo {
  z-index: 10;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 180px;
}

.footer-logo img {
  display: inline-block;
  color: transparent;
  height: auto;
  font-size: 0;
  vertical-align: middle;
  max-width: 100%;
}

.social_wrap {
  display: block;
  height: 100%;
  position: relative;
  overflow: hidden;
  float: right;
  margin-right: 10px;
}

.footer_content {
  padding-top: 14px;
}

.footer_content a {
  color: #fff;
  text-decoration: none;
}

.haydi-indir {
  -webkit-text-stroke: 5px var(--azure-radiance-3);
  color: white;
  font-weight: 400;
  line-height: normal;
  margin-right: 2px;
  margin-top: -86px;
  min-height: 72px;
  width: 492px;
  text-align: center;
}

.footer_copyright {
  left: 10px;
  position: absolute;
  top: 20px;
}

.stores {
  margin-top: 100px;
  float: left;
  position: relative;
}

.TRANSLATE {
  height: 29px;
  left: 351px;
  position: absolute;
  top: 31px;
  width: 119px;
  background: #fff;
  border-radius: 5px;
}

.card-clouds-footer .property-1-0-cloud-1-footer {
  background-image: url(../../public/img/clouds1.svg);
}

.card-clouds-footer .property-1-0-cloud-2-footer {
  background-image: url(../../public/img/clouds2.svg);
}

.card-clouds-footer .property-1-cloud-3-footer {
  background-image: url(../../public/img/clouds3.svg);
}

.lang-select span {
  color: white;
}

.lang-select li {
  color: black;
}

.flag-select__btn:after {
  border-top: 5px solid #ffffff;
}

.ru-size {
  font-size: 18px !important;
}

.ru-size-lg {
  font-size: 20px !important;
}

.flag-select__options {
  position: fixed;
  bottom: 0;
  z-index: 999999;
  background: #484f7f;
}

@media only screen and (max-width: 600px) {
  .DASHBOARD .text-wrapper-48 {
    top: 35px !important;
    color: #08a2fd !important;
  }

  .DASHBOARD .text-wrapper-49 {
    top: 35px !important;
    color: #08a2fd !important;
  }

  .DASHBOARD .header {
    left: 0;
    height: 60px;
    width: 100%;
    background: #fff;
    /* padding-left: 12%;
    padding-bottom: 13px; */
  }

  #myVideo {
    height: 377px !important;
    width: 100% !important;
  }

  .DASHBOARD .overlap-4 {
    left: 0;
  }

  .DASHBOARD .asset-5 {
    left: 299px;
  }

  .ozmo-character {
    right: 108px;
    left: initial !important;
  }

  .DASHBOARD .icon-cloud {
    left: 0;
  }

  .DASHBOARD .asset-10 {
    right: 0;
    left: initial;
  }

  .DASHBOARD .asset-3 {
    left: 1%;
  }

  .DASHBOARD .asset-2 {
    left: 5%;
  }

  .DASHBOARD .asset-4 {
    left: 54%;
  }

  .DASHBOARD .asset-1 {
    left: 0;
  }

  .DASHBOARD .asset-5 {
    left: 25%;
  }

  .DASHBOARD .asset-6 {
    right: -24%;
    left: initial;
  }

  .DASHBOARD .OZMO-adventureland-headline {
    position: relative;
    left: initial;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  /* .DASHBOARD .OZMO-adventureland-headline,
  .group-816,
  .group-818,
  .group-817 {
    left: 10% !important;
  } */

  .DASHBOARD .overlap-wrapper {
    width: 100%;
  }

  .DASHBOARD .p {
    left: 9% !important;
  }

  .DASHBOARD .overlap-group5 {
    left: 0;
  }

  .DASHBOARD .h-1,
  .DASHBOARD .group-820 {
    left: 0;
  }

  .DASHBOARD .ozmo-macera-d-nyas-nda-sizi-b-y-k-bir-ehir-kar-l-yor {
    width: 334px;
  }

  .get-on-the-google-play-badge-TR {
    left: 0 !important;
  }

  .download-on-the-app-store-badge-TR {
    left: 0 !important;
  }

  .ozmo-logo-mobile {
    position: relative;
    top: 314px;
    margin: 0 auto;
    width: 346px;
  }

  .ozmo-logo-mobile img {
    width: 240px;
    width: 240px;
    position: relative;
    left: initial;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .DASHBOARD .clouds {
    width: 100%;
    overflow: hidden;
  }

  .footer {
    top: 3000px !important;
  }

  .footer_copyright {
    float: left !important;
    background: #484f7f !important;
    text-align: left;
    margin-top: 33px;
    left: 0;
    padding-left: 10px;
  }

  .DASHBOARD .footer {
    height: 110px;
  }

  .haydi-hemen-indir {
    font-family: "Baloo", Helvetica;
    font-size: 32px;
    font-weight: 400;
    color: #fff;
  }
}


@media only screen and (min-width: 600px) {
  #myVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    left: 0;
  }
}