.card-clouds-footer {
  height: 260px;
  width: 400px;
}

.card-clouds-footer .group {
  height: 260px;
}

.card-clouds-footer .overlap-group {
  height: 260px;
  position: relative;
}

.card-clouds-footer .rectangle {
  left: 0;
  position: absolute;
  top: 0;
}

.card-clouds-footer .asset {
  left: 0;
  position: absolute;
  width: 399px;
}

.card-clouds-footer.cloud-2-footer {
  overflow: hidden;
}

.card-clouds-footer.cloud-1-footer {
}

.card-clouds-footer.cloud-3-footer {
}

.card-clouds-footer .property-1-cloud-2-footer {
  left: 1px;
  position: relative;
}

.card-clouds-footer .property-1-cloud-1-footer {
  width: 399px;
}

.card-clouds-footer .property-1-cloud-3-footer {
}

.card-clouds-footer .property-1-0-cloud-2-footer {
  width: 399px;
}

.card-clouds-footer .property-1-0-cloud-1-footer {
}

.card-clouds-footer .property-1-0-cloud-3-footer {
  width: 400px;
}

.card-clouds-footer .property-1-1-cloud-2-footer {
  height: 194px;
  width: 399px;
}

.card-clouds-footer .property-1-1-cloud-1-footer {
  height: 218px;
  width: 399px;
}

.card-clouds-footer .property-1-1-cloud-3-footer {
  height: 184px;
  width: 400px;
}

.card-clouds-footer .property-1-2-cloud-2-footer {
  height: 224px;
  top: 36px;
}

.card-clouds-footer .property-1-2-cloud-1-footer {
  height: 229px;
  top: 31px;
}

.card-clouds-footer .property-1-2-cloud-3-footer {
  height: 227px;
  top: 33px;
}
