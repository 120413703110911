.image-park {
  height: 512px;
  position: relative;
  width: 512px;
}

.image-park .playground {
  height: 296px;
  left: 21px;
  position: absolute;
  top: 108px;
  width: 471px;
}
