.image-market {
  height: 512px;
  position: relative;
  width: 512px;
}

.image-market .mask-group-4 {
  height: 409px;
  left: 32px;
  position: absolute;
  top: 52px;
  width: 448px;
}
