.ozmo-character {
  height: 512px;
  position: relative;
  width: 512px;
}

.ozmo-character .ozmo-character-1 {
  height: 482px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 15px;
  width: 512px;
}
