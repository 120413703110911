.image-character {
  height: 512px;
  overflow: hidden;
  width: 512px;
}

.image-character .group-14 {
  height: 443px;
  left: 49px;
  position: relative;
  top: 69px;
  width: 414px;
}

.image-character .div {
  height: 443px;
  position: relative;
}

.image-character .mask-group {
  height: 98px;
  left: 316px;
  position: absolute;
  top: 35px;
  width: 98px;
}

.image-character .img {
  height: 88px;
  left: 284px;
  position: absolute;
  top: 138px;
  width: 88px;
}

.image-character .mask-group-2 {
  height: 104px;
  left: 46px;
  position: absolute;
  top: 186px;
  width: 104px;
}

.image-character .mask-group-3 {
  height: 117px;
  left: 0;
  position: absolute;
  top: 72px;
  width: 117px;
}

.image-character .c-outfit {
  height: 443px;
  left: 90px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 249px;
}
