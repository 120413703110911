.image-plant {
  height: 512px;
  position: relative;
  width: 512px;
}

.image-plant .plantto {
  height: 375px;
  left: 124px;
  object-fit: cover;
  position: absolute;
  top: 68px;
  width: 264px;
}
