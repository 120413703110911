.image-pet {
  height: 512px;
  position: relative;
  width: 512px;
}

.image-pet .catto {
  height: 290px;
  left: 21px;
  position: absolute;
  top: 222px;
  width: 469px;
}
