.image-basket {
  height: 512px;
  position: relative;
  width: 512px;
}

.image-basket .basket {
  height: 465px;
  left: 184px;
  position: absolute;
  top: 25px;
  width: 143px;
}
