.image-paint {
  height: 512px;
  position: relative;
  width: 512px;
}

.image-paint .paint {
  height: 441px;
  left: 131px;
  object-fit: cover;
  position: absolute;
  top: 57px;
  width: 249px;
}
